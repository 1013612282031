<template>
  <div class="pass">
    <div class="p_content">
      <div>
        <!-- <img src="@/assets/img/signUp/icon-shenhe.png" alt="" /> -->
        <i class="icon-icon"></i>
      </div>
      <!-- {{!this.examine_status == 1}} -->
      <div v-if="this.examine_status==0">同学你好，你的报名资料已提交</div>
      <div v-if="this.examine_status == 0">
        请等待工作人员审核，审核结果将于以短信形式发送到报名手机号，请注意查收。
      </div>
      <div v-if="this.examine_status == 1">
        同学你好，你的材料已经通过审核，请点击“下一步”完成报名
      </div>
      <div v-if="this.examine_status == 2">同学你好，你的申请材料未能通过审核。原因是：{{ this.examine_reason }}</div>
      <div class="btn" v-show="this.examine_status == 2">
        <el-button @click="Lasting()">上一步</el-button>
      </div>
      <div class="btn" v-show="this.examine_status == 1">
        <el-button @click="paying()">下一步</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */

  import {signBack, signUpInit} from "r/index/signUp";

  export default {
    name: "Pass",
    data() {
      return {
        examine_status: window.localStorage.getItem(
          "examine_status" + this.$store.state.indexIdentity
        ),
        examine_reason: window.localStorage.getItem(
          "examine_reason" + this.$store.state.indexIdentity
        ),
        signBackStatus: "",
       
      };
    },
    created() {
    },

    watch: {},
    methods: {
      Lasting() {
        signBack({}).then((res) => {
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          this.signBackStatus = res.data.status;
          this.$emit("getstatus", res.data.status);
          this.$message.success(res.data.msg);
        });
      },
      paying() {
        this.$emit("is_next", 1);
      },
     
    },
  };
</script>

<style lang="scss" scoped>
  @import "a/scss/btn";
  @import "a/scss/index";
  @import "a/style";

  @font-face {
    font-family: "icomoon";
    src: url("../../../assets/fonts/icomoon.eot?dcunb6");
    src: url("../../../assets/fonts/icomoon.eot?dcunb6#iefix") format("embedded-opentype"),
    url("../../../assets/fonts/icomoon.ttf?dcunb6") format("truetype"),
    url("../../../assets/fonts/icomoon.woff?dcunb6") format("woff"),
    url("../../../assets/fonts/icomoon.svg?dcunb6#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  .btn {
    padding-bottom: 0 !important;
  }

  .pass {
    padding: 0 56px 65px 56px;

    .p_content {
      i {
        font-size: 100px;
        color: var(--color);
      }

      div {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        line-height: 18px;
      }

      :nth-child(2) {
        margin: 24px 0;
      }

      :nth-child(3) {
        margin: 0 0 50px 0;
      }
    }
  }
</style>
