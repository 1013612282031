<template>
  <!-- 确认收货地址对话框  -->
  <el-dialog
    :visible.sync="dialogVisible"
    @close="cancelBtn()"
    @open="open()"
    title="确认收货地址"
    width="40%"
  >
    <el-form :model="addressForm" :rules="addressRules" ref="addressRef">
      <el-form-item label="收件人" prop="certificate_name">
        <el-input
          clearable
          placeholder="填写真实姓名"
          v-model="addressForm.certificate_name"
        ></el-input>
      </el-form-item>
      <el-form-item label="收件人电话" prop="certificate_phone">
        <el-input
          clearable
          placeholder="填写真实电话"
          v-model="addressForm.certificate_phone"
        ></el-input>
      </el-form-item>
      <el-row :gutter="40">
        <el-col :span="8">
          <el-form-item label="收货地址" prop="certificate_province">
            <el-select
              @change="changeProvince($event)"
              clearable
              placeholder="选择省份"
              ref="certificate_province"
              v-model="addressForm.certificate_province"
            >
              <el-option
                :key="item.id"
                :label="item.name"
                :value="item.id"
                v-for="item in selectProvince"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="no_label" label=" " prop="certificate_city">
            <el-select
              clearable
              placeholder="选择城市"
              ref="certificate_city"
              v-model="addressForm.certificate_city"
            >
              <el-option
                :key="item.id"
                :label="item.name"
                :value="item.id"
                v-for="item in selectCitySchool"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="no_label" label=" " prop="county">
            <!-- <el-select
                ref="school"
                v-model="addressForm.school"
                clearable
                placeholder="选择所在区县"
              >
                <el-option
                  v-for="item in selectSchoolList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> -->
            <el-input
              clearable
              placeholder="选择所在区县"
              v-model="county"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="" prop="certificate_address">
        <el-input
          clearable
          placeholder="填写详细地址"
          ref="certificate_address"
          v-model="certificate_address"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button @click="setAddress()" type="primary">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
  /* eslint-disable */
  import {getProAndCity} from "r/index/signUp";
  import {getAddress, setAddress} from "r/index/certificate";
  import validator from "common/validator";

  export default {
    name: "deliveryAddress",
    props: {
      dialogVisibleAddress: Boolean,
    },
    data() {
      return {
        dialogVisible: false, //确认收货地址对话框
        addressRules: {
          certificate_name: [
            {required: true, message: "姓名不能为空！", trigger: "blur"},
          ],
          certificate_phone: [
            {
              required: true,
              message: "手机号不能为空！",
              trigger: "blur",
            },
            {validator: validator.validatePhone, trigger: "blur"},
          ],
          certificate_province: [
            {
              required: true,
              message: "请选择所在省份！",
              trigger: "change",
            },
          ],
          certificate_city: [
            {
              required: true,
              message: "请选择所在城市！",
              trigger: "change",
            },
          ],
          // county: [
          //   { required: true, message: "请选择所在区县！", trigger: "blur" },
          // ],
          certificate_address: [
            {required: true, message: "详细地址不能为空！", trigger: "blur"},
          ],
        },
        addressForm: {
          certificate_name: "",
          certificate_phone: "",
          certificate_province: null,
          certificate_city: null,
          certificate_address: "",
        },
        county: "", //区县
        certificate_address: "", //详细地址
        selectProvince: [], //省份
        CityName: {}, //根据省份筛选出所有城市的对象
        selectCityJiguan: [], //籍贯城市列表
        selectCitySchool: [], //就读中学-城市列表
        jgProvinceCode: "", //省份id
        jiguan_city: "", //城市id(和省份id拼接)
      };
    },
    created() {
      // this.getProAndCity();
    },
    watch: {
      dialogVisibleAddress() {
        this.dialogVisible = this.dialogVisibleAddress;
      },
    },
    methods: {
      // 确认,关闭弹框，修改父组件的值
      cancelBtn() {
        this.dialogVisible = false;
        this.$emit("closeAddressDialog", this.dialogVisible, false);
      },
      open() {
        this.getProAndCity();
        this.getAddress();
      },
      getAddress() {
        console.log(111111)
        getAddress({}).then((res) => {
          // console.log(res, "getAddress");
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          if (res.data.info == null) {
            return;
          } else {
            // console.log(Object.keys(res.data.info),'info')
            // if (Object.keys(res.data.info).length != 0) {
            //   return this.$message.error("已保存地址");
            // }
            // this.dialogVisible = true;
            this.addressForm.certificate_name = res.data.info.certificate_name;
            this.addressForm.certificate_phone = res.data.info.certificate_phone;
            this.addressForm.certificate_province =
              res.data.info.certificate_province;
            this.addressForm.certificate_city = res.data.info.certificate_city;
            this.selectCitySchool = this.getCurrentCityLists(
              this.addressForm.certificate_province
            );
            if (res.data.info.certificate_address) {
              this.county = res.data.info.certificate_address.split("-")[0];
              this.certificate_address =
                res.data.info.certificate_address.split("-")[1];
            }
          }
        });
      },

      // 获取省份城市
      getProAndCity() {
        getProAndCity({}).then((res) => {
          // console.log(res, "getProAndCity");
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          this.selectProvince = res.data.province_list;
          this.CityName = res.data.city_list;
        });
      },

      //选择籍贯省份change事件
      changeProvince(val, status) {
        console.log(val);
        this.selectCitySchool = [];
        this.selectCitySchool = this.getCurrentCityLists(val); //在此调用城市筛选列表
        if (!status) {
          this.addressForm.certificate_city = "";
        }
      },

      //返回当前省份下的城市列表
      getCurrentCityLists(val) {
        console.log(val);
        let currentCity = [];
        if (
          this.CityName == null ||
          !this.CityName ||
          Object.values(this.CityName).length == 0
        ) {
          getProAndCity({})
            .then((res) => {
              if (res.data.code === 200) {
                this.CityName = res.data.city_list;
                for (let key in this.CityName) {
                  if (this.CityName[key].parent_id == val) {
                    currentCity.push(this.CityName[key]);
                  }
                }
                return currentCity;
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        } else {
          for (let key in this.CityName) {
            if (this.CityName[key].parent_id == val) {
              currentCity.push(this.CityName[key]);
            }
          }
          return currentCity;
        }
        return currentCity;
      },
      // 提交
      setAddress() {
        this.addressForm.certificate_address =
          this.county.toString() + "-" + this.certificate_address.toString();
        this.$refs["addressRef"].validate((valid, object) => {
          console.log(valid, "valid");
          if (!valid) {
            return false;
          }
        });

        setAddress(this.addressForm).then((res) => {
          // console.log(res, "setAddress");
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          this.$message.success(res.data.msg);
          this.dialogVisible = false;
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "a/scss/dialog";

  ::v-deep.el-form-item {
    margin-bottom: 20px;

    // item的验证红点点
    .el-form-item__label {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #12141c;
    }

    &.no_label .el-form-item__label {
      width: 0 !important;
    }

    &.no_label .el-form-item__label::before {
      content: "" !important;
    }

    &.no_label .el-form-item__content {
      margin-left: 0 !important;
    }
  }
</style>
