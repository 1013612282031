<template>
  <div class="signUp">
    <div class="content">
      <div class="">
        <div
          class="lastBtn"
          v-show="
            this.status == 1 ||
            this.status == 2 ||
            this.status == 3 ||
            this.status == 4 ||
            this.status == 5
          "
        >
          <el-button @click="lasStep()">上一步</el-button>
        </div>
        <div>
          <div v-if="$store.state.human == 1" class="signUp_titile">
            课程报名
          </div>
          <div v-else class="signUp_titile">营地报名</div>
        </div>
      </div>
      <div>
        <Breadcrumb :statusNum="status"></Breadcrumb>
      </div>
    </div>
    <Confirm v-if="type == 'examInfo'" @getstatus="statuss"></Confirm>
    <Invitation
      v-else-if="type == 'invitation'"
      @getstatus="statuss"
      :invitation_code="invitation_code"
    ></Invitation>
    <ReferInfo v-else-if="type == 'referInfo'"
               @getstatus="statuss"
               :invitation_code="invitation_code"
    ></ReferInfo>
    <Pass
      v-else-if="type == 'check'"
      @is_next="is_nextevent"
      @getstatus="statuss"
    ></Pass>
    <Paying v-else-if="type == 'pay'" @getstatus="statuss"></Paying>
    <Success v-else-if="type == 'success'" @getstatus="statuss"></Success>
    <!-- <el-dialog
      v-if="$store.state.indexIdentity != '03874948cc2b4b2d8a5eb57c09ebfb2c'"
      style="font-size: 26px"
      class="info"
      title="营地声明"
      :visible.sync="dialogVisible"
      width="520px"
      center
    > -->

    <!-- 营地通知 消息列表 -->
    <!-- <el-dialog
      v-if="!$store.state.human == 1"
      style="font-size: 26px"
      class="infoList"
      title="营地通知"
      :visible.sync="dialogVisibleList"
      width="520px"
      center
    >
      <div class="msg" @click="toMsgDetail">

        <el-badge is-dot class="item title" >通知栏标题 邀请您的参与</el-badge>
        <div class="time">2022-8-1</div>
        <div class="msg_content">
          近日，营地工作组收到部分中学师生反映有机构及个人以高出营地费用的价格出售营地活动名额。
          对此，营地工作组严正声明如下：营地工作组从未授权任何单位、媒体、机构或个人举办营利性商业讲座、培训班、训练营或其他形式的营地招生活动。工作组对有违规操作行为的机构或个人保留追究法律责任的权利。
        </div>

      </div>
      <el-checkbox v-model="checked">不再提示</el-checkbox>
    </el-dialog> -->

    <!-- 营地通知 详情内容 -->
    <!-- <el-dialog
      v-if="!$store.state.human == 1"
      style="font-size: 26px"
      class="info"
      title="营地声明"
      :visible.sync="dialogVisible"
      width="520px"
      center
    >
      <div class="time">2022-8-1</div>
      <div>
        &emsp;&emsp;近日，营地工作组收到部分中学师生反映有机构及个人以高出营地费用的价格出售营地活动名额。
        对此，营地工作组严正声明如下：营地工作组从未授权任何单位、媒体、机构或个人举办营利性商业讲座、培训班、训练营或其他形式的营地招生活动。工作组对有违规操作行为的机构或个人保留追究法律责任的权利。
      </div>
      <div>
        &emsp;&emsp;希望广大师生以营地招生简章为准，不要轻信有关宣传，以免自身利益受损。如遇类似上述情况，可发送邮件至service@campcenter.cn反映核实。特此声明！
      </div>
      <div class="btn">
        <el-button type="primary" @click="toBackList">返回营地通知列表</el-button>
      </div>
    </el-dialog> -->

    <!-- 营地通知 详情内容 -->
    <el-dialog
      v-if="!$store.state.human == 1"
      style="font-size: 26px"
      class="info"
      title="营地声明"
      :visible.sync="dialogVisible"
      width="520px"
      center
    >
      <div>
        &emsp;&emsp;近日，营地工作组收到部分中学师生反映有机构及个人以高出营地费用的价格出售营地活动名额。
        对此，营地工作组严正声明如下：营地工作组从未授权任何单位、媒体、机构或个人举办营利性商业讲座、培训班、训练营或其他形式的营地招生活动。工作组对有违规操作行为的机构或个人保留追究法律责任的权利。
      </div>
      <div>
        &emsp;&emsp;希望广大师生以营地招生简章为准，不要轻信有关宣传，以免自身利益受损。如遇类似上述情况，可发送邮件至service@campcenter.cn反映核实。特此声明！
      </div>
      <div class="btn">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>

    <!-- <el-dialog
      v-if="$store.state.indexIdentity == '03874948cc2b4b2d8a5eb57c09ebfb2c'"
      style="font-size: 26px"
      class="info"
      title="活动通知"
      :visible.sync="dialogVisible"
      width="770px"
      center
    >
      <div>
        &emsp;&emsp;
        南开大学历史学院和历史学高中基础人才培养与教学实践计划执行委员会将于2022年2月开展中国近代史专题研究性学习。本次研学活动将由南开大学历史学院侯杰教授及优秀博士生主讲。
      </div>
      <div>
        &emsp;&emsp;
        在1月历史寒假营的基础上，本次研学活动将进一步开阔学生史学视野，更有针对性地学习和拓展中国近代史方向知识，多层次提高对史学资料的分析能力，养成自主探究的学习方式。
      </div>

      <div>
        &emsp;&emsp;本次活动名额有限，欢迎各中学及学生报名参加。详细内容可点击链接
        <a :href="yingdiHref">查看报名简章</a>。
      </div>
      <div class="btn">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
  /* eslint-disable */
  import {HREF_URL} from "@/config/server";
  import Breadcrumb from "c/breadcrumb";
  import Confirm from "c/index/SignUp/confirm";
  import Invitation from "c/index/SignUp/invitation";
  import ReferInfo from "c/index/SignUp/referInfo";
  import Pass from "c/index/SignUp/pass";
  import Paying from "c/index/SignUp/paying";
  import Success from "c/index/SignUp/success";
  import {signUpInit, signBack} from "r/index/signUp";
  import store from '@/store'

  export default {
    name: "signUp",
    components: {
      Breadcrumb,
      Confirm,
      Invitation,
      ReferInfo,
      Pass,
      Paying,
      Success,
    },
    data() {
      return {
        type: "examInfo",
        status: "", //进度
        doubt_code: window.localStorage.getItem(
          "doubt_code" + this.$store.state.indexIdentity
        ),
        doubt_info: window.localStorage.getItem(
          "doubt_info" + this.$store.state.indexIdentity
        ),
        doubt_check: window.localStorage.getItem(
          "doubt_check" + this.$store.state.indexIdentity
        ),
        status_val: "",
        getExamine: "",
        isNexts: "",
        signBackStatus: "",
        invitation_code: "", //邀请码
        dialogVisible: false, //营地声明弹窗
        yingdiHref: "", //营地声明链接
        dialogVisibleList: true, //营地通知列表弹窗
        checked: false, //不再提示（选框）
      };
    },
    created() {
      this.yingdiHref =
        "http://ying.testgate.cn/b4ce2ee5068848e1a91ab7d5de34ba90";
      this.type = this.$route.params.type;
      let camp_statement = window.localStorage.getItem(
        "camp_statement" + this.$store.state.indexIdentity
      );
      if (!camp_statement) {
        this.dialogVisible = true; //营地通知列表弹窗
        window.localStorage.setItem("camp_statement" + this.$store.state.indexIdentity,
          "1"
        );
      }
      // this.statuss();
      this.signUpInit();
    },
    watch: {
      $route: {
        handler(val) {
          const that = this;
          if (val.path.indexOf("signUp") != -1) {
            that.type = val.params.type;
          }
        },
        deep: true,
      },
      status(val) {
        // console.log(val, "status");
      },
    },
    methods: {
      // 营地通知 详情内容
      // toMsgDetail() {
      //   this.dialogVisibleList = false;
      //   this.dialogVisible = true;
      // },
      // 返回营地通知列表
      // toBackList() {
      //   this.dialogVisibleList = true;
      //   this.dialogVisible = false;
      // },
      // 上一步回退
      lasStep() {
        signBack({}).then((res) => {
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          this.signBackStatus = res.data.status;
          this.signUpInit(this.signBackStatus);
          this.$message.success(res.data.msg);
        });
      },
      is_nextevent(val) {
        this.isNexts = val;
        this.signUpInit(this.status_val);
      },
      // 点击下一步，触发父组件方法
      statuss(val) {
        this.status_val = val;
        this.signUpInit(this.status_val);
      },
      // status=4,跳到审核页面；status=3时，根据doubt_check做判断
      signUpInit(status_val = null) {
        signUpInit({}).then((res) => {
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          // 点击下一步传值给status,更新
          if (status_val && status_val != null && status_val != "") {
            this.status = status_val;
          } else {
            this.status = res.data.status;
          }
          // doubt_code，doubt_info，doubt_check。0为不填写，1为填写
          if (this.status == 0) {
            this.type = "examInfo";
          } else if (this.status == 1) {
            //  是否填写邀请码
            console.log(this.doubt_code)
            if (this.doubt_code == 0) {
              this.type = "referInfo";
              this.status = 2;
            } else {
              this.type = "invitation";
              this.status = 1;
            }
          } else if (this.status == 2) {
            // 是否填写个人资料
            if (this.doubt_info == 0) {
              this.type = "check";
              this.status = 3;
            } else {
              this.type = "referInfo";
              this.status = 2;
            }
          } else if (this.status == 3) {
            // 是否审核个人资料
            if (this.doubt_check == 0) {
              this.type = "pay";
              this.status = 4;
            } else {
              this.type = "check";
              this.status = 3;
            }
          } else if (this.status == 4) {
            this.type = "check";
            this.status = 3;
            if (this.isNexts == 1) {
              if (res.data.examine_status == 1) {
                this.type = "pay";
                this.status = 4;
              } else if (res.data.examine_status == 2) {
                this.type = "referInfo";
                this.status = 2;
              }
            }
          } else if (this.status == 5) {
            this.type = "pay";
          } else if (this.status == 6) {
            this.type = "success";
          }
          this.$emit("getStatus", this.type);
          this.invitation_code = res.data.invitation_code; //填写的邀请码回显需要
          //订单支付金额
          let code = this.$store.state.indexIdentity;
          window.localStorage.setItem("has_amount" + code, res.data.has_amount);
          window.localStorage.setItem("campsite_id" + code, res.data.campsite_id);
          window.localStorage.setItem("order_no" + code, res.data.order_no);
          window.localStorage.setItem("examine_status" + code,
            res.data.examine_status
          ); //返回错误原因
          window.localStorage.setItem("examine_reason" + code,
            res.data.examine_reason
          ); //资料审核状态
          // 回显上传的相关资料图片
          const aPath1 = res.data.transcripts;
          const aPath2 = res.data.achievementCertificate;
          const aPath3 = res.data.safetyNotices;
          const aPath4 = res.data.achievement_path;
          if (aPath1) {
            let arr = aPath1.split(",");
            let fileList = [];
            fileList = arr.map((item) => {
              let obj = {
                url: item,
              };
              return obj;
            });
            window.localStorage.setItem("sign_up_file1_" +
              window.localStorage.getItem("index-phone-all") +
              code,
              JSON.stringify(fileList)
            ); //上传的图片
          }
          if (aPath2) {
            let arr = aPath2.split(",");
            console.log(arr)
            let fileList = [];
            fileList = arr.map((item) => {
              let obj = {
                url: item,
              };
              return obj;
            });
            window.localStorage.setItem("sign_up_file2_" +
              window.localStorage.getItem("index-phone-all") +
              code,
              JSON.stringify(fileList)
            ); //上传的图片
          }
          if (aPath3) {
            let arr = aPath3.split(",");
            let fileList = [];
            fileList = arr.map((item) => {
              let obj = {
                url: item,
              };
              return obj;
            });
            window.localStorage.setItem("sign_up_file3_" +
              window.localStorage.getItem("index-phone-all") +
              code,
              JSON.stringify(fileList)
            ); //上传的图片
          }
          if (aPath4) {
            let arr = aPath4.split(",");
            let fileList = [];
            fileList = arr.map((item) => {
              let obj = {
                url: item,
              };
              return obj;
            });
            window.localStorage.setItem("sign_up_file4_" +
              window.localStorage.getItem("index-phone-all") +
              code,
              JSON.stringify(fileList)
            ); //上传的图片
          }
          // 银行截图
          const bank_Path = res.data.bank_info;
          if (bank_Path != null) {
            let arr = bank_Path.split(",");
            let fileList = [];
            fileList = arr.map((item) => {
              let obj = {
                url: item,
              };
              return obj;
            });
            window.localStorage.setItem("sign_up_payfile_" + window.localStorage.getItem("index-phone-all") + code,
              JSON.stringify(fileList)
            ); //上传的图片
          } else {
            window.localStorage.setItem("sign_up_payfile_" + window.localStorage.getItem("index-phone-all") + code,
              "[]"
            );
          }
          //多活動形式
          window.localStorage.setItem("multiform_id" + code, res.data.multiform_id);
          //vuex-个人信息
          store.state.signUpInfo = res.data
        });
      },
    },
  };
</script>

<style lang="scss">
  @import "a/scss/lastBtn";

  .flex {
    display: flex;
  }

  .item {
    margin-top: 10px;
    margin-right: 40px;
  }

  .el-dialog__wrapper {
    transition-duration: 0.3s;
  }

  .btn {
    text-align: center;
    // padding-bottom: 224px;
    .el-button {
      width: 120px;
      height: 35px;
      border-radius: 4px;
      border-color: var(--color);
      background: var(--color);
      opacity: 0.8;
      line-height: 35px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      padding: 0;
      margin: 30px 0 0 0;
    }
  }

  .signUp {
    /*background: #ffffff;*/
    /*width: 856px;*/
    .signUp_titile {
      font-size: 24px;
      font-weight: 500;
      color: #1b1419;
      line-height: 24px;
      margin-bottom: 40px;
      text-align: center;
    }

    .content {
      padding: 70px 55px 50px;
    }
  }

  // 营地通知列表
  .infoList {
    .el-dialog__header {
      margin: 30px 20px 0 20px;

      .el-dialog__title {
        font-size: 24px;
      }
    }

    .el-dialog__body {
      padding: 0;
    }

    div {
      // font-size: 16px;
      line-height: 27px;
    }

    .msg {
      background-color: #f2f2f2;
      padding: 10px 15px;

      .title {
        width: 100%;
        font-size: 17px;
        font-weight: 600;
      }

      .time {
        font-size: 15px;
        color: #a9a9a9;
      }

      .msg_content {
        font-size: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  // 营地通知声明
  .info {
    .el-dialog__header {
      margin: 30px 20px 0 20px;

      .el-dialog__title {
        font-size: 24px;
      }
    }

    .el-dialog__body {
      padding: 0 25px 25px 30px;
    }

    div {
      font-size: 17px;
      line-height: 30px;
    }

    .time {
      text-align: center;
      font-size: 15px;
      color: #a9a9a9;
    }
  }
</style>

<style lang="scss" scoped>
  @import "a/scss/dialog";
</style>
